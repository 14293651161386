<template>
  <div>
    <!-- refundDetailed 退款明细 -->
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--申请退款时间-->
          <div class="conditions">
            <div>申请退款时间：</div>
            <el-date-picker
              :default-time="['00:00:00', '23:59:59']"
              value-format="timestamp"
              style="width: 220px;margin-left: 10px;"
              v-model="advancedForm.times"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>

          <!-- 关键字-->
          <div class="conditions">
            <div>关键字选择：</div>
            <el-select
              class="ipt-default"
              style="width:120px"
              v-model="advancedForm.keyword_type"
              clearable
            >
              <el-option value="order_sn" label="根据订单编号"></el-option>
              <el-option value="sn" label="根据交易单号"></el-option>
              <el-option value="goods_sn" label="根据活动编号"></el-option>
              <el-option value="member_name" label="根据买家姓名"></el-option>
              <el-option value="phone" label="根据买家手机号"></el-option>
            </el-select>
            <el-input
              class="ipt-default"
              style="width: 220px"
              v-model="advancedForm.keyword"
              placeholder="请输入关键词"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
            <el-button class="btn-default" @click="searchEvent">导出</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="shop_name" label="商户名称" width="180" />

        <el-table-column label="申请退款时间" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="退款金额" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.refund_price?scope.row.refund_price:0 | unitPrice("￥")}}</template>
        </el-table-column>

        <el-table-column prop="launch_status" label="退款类型" width="180">
          <template slot-scope="scope">{{ scope.row.launch_status===1?'用户发起':'商家发起'}}</template>
        </el-table-column>
        <el-table-column prop="sn" label="交易单号" width="180" />
        <el-table-column prop="order_sn" label="订单编号" width="180" />
        <el-table-column prop="goods_sn" label="活动编号" width="180" />
        <el-table-column label="买家/手机号" :formatter="handlePhone" width="180" />
        <el-table-column label="退款完成时间" width="180">
          <template
            slot-scope="scope"
          >{{scope.row.refund_time? (scope.row.refund_time | unixToDate('yyyy-MM-dd hh:mm:ss')):'————' }}</template>
        </el-table-column>
        <el-table-column prop="refund_status" label="退款状态" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.refund_status==='COMPLETED'?'退款异常':scope.row.refund_status==='SUCCESS'?'退款成功':scope.row.refund_status==='REFUNDCLOSE'?'退款关闭':'退款处理中'}}</template>
        </el-table-column>
        <el-table-column label="退款失败原因" width="180">
          <template slot-scope="scope">{{scope.row.refund_fail_reason }}</template>
        </el-table-column>
        <el-table-column prop="service_status" label="订单状态" width="180">
          <template slot-scope="scope">
            {{ scope.row.service_status==='NEW'?'新订单':
            scope.row.service_status==='CONFIRM'?'已确认':
            scope.row.service_status==='PAID_OFF'?'已付款':
            scope.row.service_status==='NOT_APPLY'?'未申请':
            scope.row.service_status==='APPLY'?'申请取消':
            scope.row.service_status==='PASS'?'审核通过':
            scope.row.service_status==='COMPLETE'?'已取消':
            '已失效不允许申请售后'}}
          </template>
        </el-table-column>
        <el-table-column prop="order_remarks" label="订单备注" width="180" />
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleScaleEdit(scope.row)">查看订单详情</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Account from "@/api/account";
import { Foundation } from "@/libs/common/utils";
export default {
  name: "refundDetailed",
  // watch: {
  //   "addMemberForm.region"(next, prev) {
  //     if (next && next.length) {
  //       this.$refs.addMemberForm.clearValidate("region");
  //     }
  //   }
  // },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近3日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近7日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },

          {
            text: "近30日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },
      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm: {
        keyword: "", // 根据关键字搜索
        times: "",
        trade_type: "", // 交易类型
        in_type: "", // 入账交易类型， 1借（收款）2贷（付款）
        trade_sn: "",
      },
    };
  },

  mounted() {
    this.GET_MemberList();
    console.log(this.$route.params.id, "1");
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_MemberList();
    console.log(this.$router.params.id);
    console.log(to, from, next);
    if (this.$router.params.id) next();
  },
  methods: {
    /** 格式化数据 */
    handlePhone(row) {
      const phone = Foundation.secrecyMobile(
        row.phone ? row.phone : 13811111111
      );
      const member_name = row.member_name;
      return member_name + " / " + phone;
    },
    /** 查看订单详情 */
    handleScaleEdit(row) {
      this.$router.push({ name: '订单详情', params: {order_id: row.order_id} })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_MemberList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_MemberList();
    },

    /** 搜索事件触发 */
    searchEvent() {
      // Object.keys(this.advancedForm).forEach(key => delete this.params[key]);
      this.params.page_no = 1;
      this.params = {
        ...this.params,
        ...this.advancedForm,
      };
      delete this.params["order_sn"];
      delete this.params["sn"];
      delete this.params["goods_sn"];
      delete this.params["member_name"];
      delete this.params["phone"];
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.advancedForm.times) {
        this.params.start_time = parseInt(this.advancedForm.times[0] / 1000);
        this.params.end_time = parseInt(this.advancedForm.times[1] / 1000 + 86399);
      }
      this.params[this.advancedForm.keyword_type] = this.advancedForm.keyword;
      delete this.params.times;
      delete this.params.keyword;
      delete this.params.keyword_type;
      this.GET_MemberList();
    },
    /** 获取列表 */
    GET_MemberList() {
      // this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      console.log(params, "paramsparams");
      API_Account.getRefundDetail(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.elTabBox {
  /deep/ .el-tabs__item {
    background: #fff;
  }

  /deep/ .is-active {
    background: #008080;
    color: #fff;
  }

  /deep/ #tab-first {
    border-radius: 4px 0 0 4px;
  }

  /deep/ #tab-second {
    border-radius: 0 4px 4px 0;
  }
}

.el-date-editor.el-input {
  width: 180px;
}

/deep/ .form-item-sex .el-form-item__content {
  width: 180px;
}

/deep/ .form-item-region .el-form-item__content {
  min-width: 180px;
}
/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
.inner-toolbar {
  flex-wrap: wrap;
}
div.toolbar-btns {
  display: flex;
  justify-content: inherit;
  width: 100%;
}

div.toolbar-btns .conditions {
  margin-right: 0px;
}
.conditionss {
  margin-top: 5px;
}
</style>
